<template>
  <NoSSR>
    <SfSection v-if="shouldDisplaySlider && recommendedProducts.length" :title-heading="header" class="section section-bestsellers">
      <m-product-carousel
        v-if="hasRecommendations"
        :products="recommendedProducts"
        :key="sliderId"
      />
    </SfSection>
  </NoSSR>
</template>

<script>
import NoSSR from 'vue-no-ssr';
import SfSection from '@storefront-ui/vue/src/components/molecules/SfSection/SfSection.vue';
import { SearchQuery } from 'storefront-query-builder'
import MProductCarousel from '~/theme/components/molecules/m-product-carousel';
import config from 'config'
import { isServer } from '@vue-storefront/core/helpers';
import { mapGetters } from 'vuex';
export default {
  name: 'MRecommendedProducts',
  components: {
    MProductCarousel,
    SfSection,
    NoSSR
  },
  props: {
    sliderId: {
      type: String,
      default: null
    },
    header: {
      type: String,
      required: true
    },
    products: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      m2Recommendations: []
    }
  },
  computed: {
    ...mapGetters({
      m2TopSliderId: 'cmsBlock/recommendationsSliderCategoryIdTop',
      m2BottomSliderId: 'cmsBlock/recommendationsSliderCategoryIdBottom'
    }),
    hasRecommendations () {
      return this.recommendedProducts.length > 0
    },
    productSkus () {
      return this.recommendedProducts.map(prod => prod.sku)
    },
    recommendedProducts () {
      return this.products ? this.products : this.m2Recommendations
    },
    shouldDisplaySlider () {
      return this.$route.query?.h !== 'slds' && this.$route.params?.h !== 'slds' // Query param for hiding sliders
    }
  },
  methods: {
    async updateRecommendedProducts () {
      if (isServer) return
      if (this.hasRecommendations) return
      this.m2Recommendations = await this.getMagentoRecommendations()
    },

    async fetchElasticProducts (query, size = null) {
      const queryObject = new SearchQuery()
      queryObject.applyFilter(query)
      let args = {
        query: queryObject,
        options: {
          populateRequestCacheTags: false,
          prefetchGroupProducts: false,
          separateSelectedVariant: true
        }
      }
      if (size) args.size = size
      let { items } = await this.$store.dispatch('product/findProducts', args)
      return items
    },

    async getMagentoRecommendations () {
      const isTopSlider = this.sliderId ? this.sliderId.indexOf('top') !== -1 : null
      const slider = isTopSlider ? this.m2TopSliderId : this.m2BottomSliderId
      const query = { key: 'category_ids', value: { in: slider } }
      const size = config.productSliders.magento.productsPerSlider
      if (!query.value.in || query.value.in.length === 0) return
      const items = await this.fetchElasticProducts(query, size)
      return items
    }
  },

  watch: {
    productSkus: {
      handler () {
        if (isServer) return
        if (this.hasRecommendations) {
          this.$bus.$emit('recommended-slider-updated', this.recommendedProducts)
          return
        }
        this.updateRecommendedProducts()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.section-recommended {
  min-height: 29rem;
@include for-desktop {
  min-height: 28rem;
}
transition: min-height .2s ease-out;
}
</style>
